import { Routes } from '@angular/router'

export const reportsRoutes: Routes = [
  {
    path: 'reports',
    loadComponent: () => import('../reports/pages/reports/reports.component'),
  },

  {
    path: 'sales-projection',
    loadComponent: () => import('../shared/components/empty-state/empty-section.component'),
  },
]
