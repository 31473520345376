/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core'
import { DropdownPanel } from './dropdown-panel'
import { CommonModule } from '@angular/common'
import { DropdownTriggerForDirective } from './dropdown-trigger-for.directive'

@Component({
  standalone: true,
  selector: 'sarbo-dropdown',
  imports: [CommonModule, DropdownTriggerForDirective],
  templateUrl: './dropdown-custom.component.html',
})
export class DropdownCustomComponent implements DropdownPanel {
  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>
  @Output() closed = new EventEmitter<void>()
  @Input() isMultiple = false

  close(): void {
    if (!this.isMultiple) {
      this.closed.emit()
    }
  }
}
