// Local Environment as default
const apiBaseUrl = import.meta.env.NG_APP_API ?? 'https://api.mcdgt.back0ffice.com'

export const environment = {
  TIME_DELAY: 90,
  envName: import.meta.env.NG_APP_ENV ?? 'local',
  cdnUrl: import.meta.env.NG_APP_CDN ?? 'https://cdn.mcdgt.back0ffice.com',
  KEY_STORAGE: import.meta.env.NG_APP_KEY_STORAGE ?? ')6QQlL720#a@v"uD,59l',

  appmngmntServiceUrl: `${apiBaseUrl}/appmngmnt-service`,
  ConfigServiceUrl: `${apiBaseUrl}/config-service`,
  incomes: `${apiBaseUrl}/incomes`,
  inventoryManagementUrl: `${apiBaseUrl}/inventory-management`,
  purchaseManagementUrl: `${apiBaseUrl}/purchase-management`,
  qcrManagement: `${apiBaseUrl}/qcr-management`,
  sarboAgent: `${apiBaseUrl}/sarbo-agent`,
  tmpUserManagement: `${apiBaseUrl}/tmp-user-management`,
  userManagement: `${apiBaseUrl}/user-management`,

  authUrl: `${apiBaseUrl}/bo/security`,
  taskTrackerUrl: `${apiBaseUrl}/bo/task-tracker`,
  translationsUrl: `${apiBaseUrl}/bo/translations`,
  tsIncomes: `${apiBaseUrl}/bo/ts-incomes`,
  tsInventory: `${apiBaseUrl}/bo/ts-inventory`,
}

