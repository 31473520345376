/* eslint-disable no-console */
import { Component, OnDestroy, OnInit, ViewChild, inject, signal } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu'
import { MatButtonModule } from '@angular/material/button'
import { RouterLinkActive, RouterLinkWithHref } from '@angular/router'
import { SnackbarComponent } from '../snackbar/snackbar.component'
import { CompaniesAndStoresService } from '../../../login/services/companies-and-stores/companies-and-stores.service'
import { Company, LastLocationRequest, Store } from '../../../login/models/company-store.model'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { COMPANY_ICON, LOGOUT_ICON } from '../../../../assets/icon'
import { DataService } from '../../services/data/data.service'
import { MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { LoadingService } from '../../../loaders/loading.service'
import { TaskTrackerService } from '../../services/task-tracker/task-tracker.service'
import { Subscription, combineLatest, firstValueFrom, tap } from 'rxjs'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { TaskGroup, TaskTracker } from '../../models/task-tracker.model'
import { ComponentUtils } from '../../utils/component-utils'
import { AuthService } from '../../../../app/services/auth.service'
import { DropdownCustomComponent } from '../dropdown-custom/dropdown-custom.component'
import { DropdownTriggerForDirective } from '../dropdown-custom/dropdown-trigger-for.directive'
import { ConnectedPosition } from '@angular/cdk/overlay'
import { TranslationService } from '../../services/translation/translation.service'
import { DialogComponent, DialogData } from '../dialog/dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { Language } from '../../types/language.type'
import { UsersAdminService } from '../../../admin/services/users-admin.service'

@Component({
  selector: 'sarbo-dropdown-menu',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    RouterLinkWithHref,
    RouterLinkActive,
    MatIconModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    DropdownCustomComponent,
    DropdownTriggerForDirective,
  ],
  templateUrl: './dropdown-menu.component.html',
  styleUrl: './dropdown-menu.component.scss',
})
export class DropdownMenuComponent implements OnInit, OnDestroy {
  private snack = inject(SnackbarComponent)
  private authService = inject(AuthService)
  private iconRegistry = inject(MatIconRegistry)
  private sanitizer = inject(DomSanitizer)
  private companiesAndStoresService = inject(CompaniesAndStoresService)
  private loadingService = inject(LoadingService)
  private taskTrackerService = inject(TaskTrackerService)
  dialog = inject(MatDialog)
  dataService = inject(DataService)
  private translate = inject(TranslateService)
  translateService = inject(TranslateService)
  private translations = inject(TranslationService)
  availableLanguages$ = this.translations.languages.asObservable()

  currentLanguage: string = this.translate.defaultLang
  selectedLanguage = ''
  usersAdminService = inject(UsersAdminService)

  @ViewChild(MatMenuTrigger) calendar: MatMenuTrigger | undefined

  private username = ''
  private allAvailableStores: Store[] = []
  private lastCallBusinessDate: Date | null = null
  availableCompanies: Company[] = []
  currentStore: Store = { storeId: 0, storeName: '', storeCode: 0, storeTimezone: 99 }
  otherAvailableStores: Store[] = []
  nameToShow = ''

  today = new Date()
  selectedWorkDay: Date = this.loadingService.workDate.value

  groups: TaskGroup[] = []
  tabSelected = signal<string>('')

  permissions: string[] = []

  tasks: TaskTracker[] = []

  subscriptions: Subscription[] = []

  connectedPosition: ConnectedPosition = {
    originX: 'end',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    offsetY: 97,
    offsetX: 0,
  }

  constructor() {
    this.subscriptions.push(
      combineLatest([
        this.loadingService.workDate$,
        this.loadingService.storeZone$,
        this.taskTrackerService.updateTaskTracker$,
      ]).subscribe({
        next: (_) => {
          this.getTasks()
          this.getWorkDateInventoryClosure()
        },
      }),
      this.loadingService.businessDate.subscribe({
        next: (businessDate) => {
          if (businessDate.setDate) {
            this.selectWorkDate(businessDate.bussinesDate)
          }
        },
      }),
    )
  }

  languageSelected = ''
  async ngOnInit(): Promise<void> {
    this.selectedLanguage = localStorage.getItem('language') ?? this.translate.defaultLang
    this.languageSelected = localStorage.getItem('language') ?? this.translate.defaultLang
    await firstValueFrom(this.translate.use(this.selectedLanguage.toLowerCase()))

    this.loadingService.businessDate.next({ bussinesDate: new Date(), setDate: false })
    this.username = this.dataService.getItem('username')
    this.iconRegistry.addSvgIconLiteral('company', this.sanitizer.bypassSecurityTrustHtml(COMPANY_ICON))
    this.iconRegistry.addSvgIconLiteral('door-open', this.sanitizer.bypassSecurityTrustHtml(LOGOUT_ICON))
    this.availableCompanies = this.dataService.signalUserCompanies()
    this.getAvailableStores()
    this.permissions = JSON.parse(this.dataService.getItem('permissions'))
    this.getTasks()
    this.getRoles()
    this.getMaxDate()
  }

  ngOnDestroy(): void {
    ComponentUtils.removeSubscriptions(this.subscriptions)
  }

  getMaxDate(): void {
    const storeTimezone = Number(this.dataService.signalStoreTimezone() || 0)
    const today = new Date()
    const offset = today.getTimezoneOffset()
    const utc = today.getTime() + offset * 60000
    const storeTime = utc + storeTimezone * 3600000
    this.today = new Date(storeTime)
  }

  public getTasks(): void {
    this.taskTrackerService
      .getTasks()
      .pipe(
        tap((tasks) => {
          this.groups = tasks.groups.filter((group) => group.permissions.some((p) => this.permissions.includes(p)))
          if (this.groups.length > 0) {
            this.tabSelected.set(this.groups[0].name)
          }
        }),
      )
      .subscribe((tasks) => {
        this.tasks = tasks.tasks
        this.taskTrackerService.tasks.next(tasks.tasks)
      })
  }

  public getRoles(): void {
    this.usersAdminService.getUser().subscribe({
      next: (user) => {
        this.dataService.setItem({ key: 'role', value: user.roles[0].roleName })
        this.dataService.updateSignals()
      },
    })
  }

  private getAvailableStores(): void {
    this.currentStore = this.dataService.signalLastStore()
    this.allAvailableStores = this.dataService.signalCurrentCompanyStores()
    this.otherAvailableStores = this.allAvailableStores.filter((store) => store.storeId !== this.currentStore?.storeId)
  }

  logout(): void {
    this.authService.logout()
  }

  changeStore(selectedStore: number): void {
    const newStore: Store = this.allAvailableStores.find((store) => store.storeId === selectedStore) ?? {
      storeId: 0,
      storeName: '',
      storeCode: 0,
      storeTimezone: 99,
    }
    this.dataService.setItem({ key: 'lastStore', value: newStore })
    this.dataService.updateSignals()
    this.getAvailableStores()

    const lastLocation: LastLocationRequest = {
      companyId: this.dataService.signalLastCompany().companyId,
      storeId: newStore?.storeId,
    }
    this.companiesAndStoresService.updateLastLocation(this.username, lastLocation).subscribe({
      next: () => {
        this.dataService.setItem({ key: 'lastStore', value: newStore })
        this.dataService.updateSignals()
        this.loadingService.storeZone.next(newStore.storeId)
      },
    })
    this.snack.openSnackBar(
      this.translateService.instant('profileMenu.toasts.changeStore') + ' ' + newStore.storeName,
      'X',
      'success',
      5000,
    )
  }

  selectWorkDate(event: Date): void {
    this.selectedWorkDay = event
    this.loadingService.workDate.next(event)
    this.calendar?.closeMenu()
  }

  selectTab(tab: string): void {
    this.tabSelected.set(tab)
  }

  getTasksStatus(tabSelected: string): string {
    const tasksStatus = `${this.completeTaskForType(tabSelected)} / ${
      this.totalTaskForType(tabSelected) - this.completeTaskForType(tabSelected)
    }`
    return `${this.translateService.instant('profileMenu.taskTracker.status').replace('${}', tasksStatus)}`
  }

  completeTaskForType(tabSelected: string): number {
    return this.tasks.filter((task) => task.group === tabSelected && task.completed).length
  }

  totalTaskForType(tabSelected: string): number {
    return this.tasks.filter((task) => task.group === tabSelected).length
  }

  getWorkDateInventoryClosure(): void {
    const now = new Date()
    if (!this.lastCallBusinessDate || now.valueOf() - this.lastCallBusinessDate.valueOf() > 100) {
      this.lastCallBusinessDate = new Date()
      this.taskTrackerService.getWorkDateInventoryClosure().subscribe({
        next: (workDateInventoryClosure) => {
          this.loadingService.closureInventoryWorkDate.next(workDateInventoryClosure)
        },
        error: (error) => {
          console.error('Error getting work date inventory closure', error)
        },
      })
    }
  }


  languageChangeAttempt(language: Language): void {
    console.log('🚀 ~ DropdownMenuComponent:', language)
    if (language.code.toLowerCase() !== this.selectedLanguage.toLowerCase()) {
      this.selectedLanguage = language.code
      this.openDialogChangeLanguage()
    }
  }

  async openDialogChangeLanguage(): Promise<void> {
    const language = await firstValueFrom(this.translate.get(`languages.${this.selectedLanguage}`))
    const title = await firstValueFrom(this.translate.get(`modals.changeLanguageConfirmation.title`, { language }))

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: title,
        message: this.translate.instant('modals.changeLanguageConfirmation.message', { language }),
        buttonText02: this.translate.instant('modals.changeLanguageConfirmation.buttons.cancel'),
        buttonColor02: 'primary',
        buttonStyle02: 'stroked',
        buttonText01: this.translate.instant('modals.changeLanguageConfirmation.buttons.switch'),
        buttonColor01: 'primary',
        buttonStyle01: 'flat',
      } as DialogData,
    })

    dialogRef.afterClosed().subscribe((action) => {
      if (action === this.translate.instant('modals.changeLanguageConfirmation.buttons.switch')) {
        this.languageSelected = this.selectedLanguage
        this.changeLanguage(this.selectedLanguage)
      }
    })
  }

  async changeLanguage(language: string): Promise<void> {
    await firstValueFrom(this.translate.use(language.toLowerCase()))
    this.currentLanguage = language
    localStorage.setItem('language', language)
    this.snack.openSnackBar(
      this.translate.instant('changeLanguage.changeLanguageConfirmation', {
        language: this.translate.instant(`languages.${language}`),
      }),
      'X',
      'success',
      5000,
    )
  }
}
