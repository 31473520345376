import { HttpClient } from '@angular/common/http'
import { Injectable, Injector, inject } from '@angular/core'
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs'
import { LoginRequest, LoginResponse, CheckPinResponse, LoginResponseError } from '../login/models/login.model'
import { ValidatePinRequest } from '../login/models/validate-pin.model'
import { DataService } from '../shared/services/data/data.service'
import { environment } from '../../environments/environment'
import { Router } from '@angular/router'
import { SnackbarComponent } from '../shared/components/snackbar/snackbar.component'
import { MatDialog } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private http = inject(HttpClient)
  private dataService = inject(DataService)
  private router = inject(Router)
  private snack = inject(SnackbarComponent)
  private dialog = inject(MatDialog)

  constructor(private injector: Injector) {}

  isIdleWatching: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  isIdleWatching$ = this.isIdleWatching.asObservable()

  login(credentials: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${environment.authUrl}/authenticate`, credentials, {
      headers: { excludeCompanyInfoHeader: 'true' },
    })
  }

  isUserLoggedIn(): boolean {
    return !!this.dataService.getItem('authToken')
  }

  async refreshToken(): Promise<void> {
    try {
      const response = await firstValueFrom(
        this.http.post<{ token: string; inactiveSessionTimeout: number }>(
          `${environment.authUrl}/refresh-token`,
          {
            token: this.dataService.getItem('refreshToken'),
          },
          { headers: { skipLoadingSpinner: 'true' } },
        ),
      )
      this.dataService.setItem({ key: 'authToken', value: response.token })
    } catch (ex) {
      console.error('Failed to refresh token, logging out...', ex)
      this.logout().catch((err) => console.error('Error during logout:', err))
    }
  }

  async logout(action?: () => Promise<void>, timeout = false): Promise<void> {
    const translateService = this.injector.get(TranslateService)
    try {
      this.dataService.signalLastUrl.set(this.router.url)
      await firstValueFrom(
        this.http.post(`${environment.authUrl}/log-out`, {}, { headers: { skipLoadingSpinner: 'true' } }),
      )
    } catch {
      // juan.martinez: the next catch block is exactly the same as the finally block because the logout attempt in the try  block was returning 502
      this.dialog.closeAll()
      this.isIdleWatching.next(false)
      if (timeout) {
        this.snack.openSnackBar(translateService.instant('login.toasts.sessionExpired'), 'X', 'error', -1)
        setTimeout(() => {
          this.dataService.signalLastUrl.set('')
        }, 1000 * 60 * 60) // 1 hour
        this.dataService.clearCleanableSignals()
      } else {
        this.snack.openSnackBar(translateService.instant('login.toasts.sessionLogout'), 'X', 'success', 20000)
        this.dataService.clearAllSignals()
        this.dataService.clearLocalData()
      }

      await this.router.navigate(['']).then(() => {
        if (action) {
          action().catch((err) => console.error('Error in logout action:', err))
        }
      })
    } finally {
      this.dialog.closeAll()
      this.isIdleWatching.next(false)
      if (timeout) {
        this.snack.openSnackBar(translateService.instant('login.toasts.sessionExpired'), 'X', 'error', -1)
        setTimeout(() => {
          this.dataService.signalLastUrl.set('')
        }, 1000 * 60 * 60) // 1 hour
        this.dataService.clearCleanableSignals()
      } else {
        this.snack.openSnackBar(translateService.instant('login.toasts.sessionLogout'), 'X', 'success', 20000)
        this.dataService.clearAllSignals()
        this.dataService.clearLocalData()
      }

      await this.router.navigate(['']).then(() => {
        if (action) {
          action().catch((err) => console.error('Error in logout action:', err))
        }
      })
    }
  }

  forgotPassword(username: string): Observable<{ email: string }> {
    return this.http.post<{ email: string }>(`${environment.authUrl}/forgot-password`, { username })
  }

  validatePin(validatePinRequest: ValidatePinRequest): Observable<CheckPinResponse> {
    return this.http.post<CheckPinResponse>(`${environment.authUrl}/check-pin`, validatePinRequest)
  }

  changePassword(password: string): Observable<LoginResponse> {
    const tempToken = this.dataService.getItem('tempToken')
    return this.http.post<LoginResponse>(`${environment.authUrl}/change-password`, { password, tempToken })
  }

  submitNewPin(pin: string): Observable<LoginResponse> {
    const tempToken = this.dataService.getItem('tempToken')
    return this.http.post<LoginResponse>(`${environment.authUrl}/set-pin`, { pin, tempToken })
  }

  validateMFA(code: string): Observable<LoginResponse> {
    const tempToken = this.dataService.getItem('tempToken')
    return this.http.post<LoginResponse>(`${environment.authUrl}/valid-mfa`, { code, tempToken })
  }

  resendMFA(): Observable<LoginResponseError> {
    const tempToken = this.dataService.getItem('tempToken')
    return this.http.post<LoginResponseError>(`${environment.authUrl}/new-mfa`, { tempToken })
  }
}
