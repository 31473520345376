import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core'
import { Router, provideRouter, withComponentInputBinding } from '@angular/router'
import { provideAnimations } from '@angular/platform-browser/animations'
import { routes } from './app.routes'
import { provideHttpClient, HttpClient, HTTP_INTERCEPTORS, withInterceptorsFromDi } from '@angular/common/http'
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core'
import { CustomTranslateLoader } from './loaders/translateLoader'
import { TranslationService } from '../app/shared//services/translation/translation.service'
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core'
import { DatePipe, registerLocaleData } from '@angular/common'
import { LoadingInterceptor } from './loaders/loading.interceptor'
import { provideEnvironmentNgxMask } from 'ngx-mask'
import * as Sentry from '@sentry/angular'
import localePy from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';

registerLocaleData(localeEn, 'en')
registerLocaleData(localePy, 'es');

export function initApp(translateService: TranslateService) {
  translateService.use('en').toPromise();
  return () => translateService.get([
    'sales'

  ]).toPromise();
}

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [TranslateService],
      multi: true,
    },
    provideEnvironmentNgxMask(),
    provideRouter(routes, withComponentInputBinding()),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useClass: CustomTranslateLoader,
          deps: [HttpClient, TranslationService],
        },
      }),
      MatNativeDateModule,
    ),
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
}
