import { Routes } from '@angular/router'
import { PermissionsEnum } from '../shared/models/permission-based-access.model'
import { authGuard } from '../shared/guards/permission.guard'
const emptySection = () => import('../shared/components/empty-state/empty-section.component')

export const inventoryRoutes: Routes = [
  {
    path: '',
    loadComponent: emptySection,
  },
  {
    path: 'movements',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.inventory.movements',
      permissionsNeeded: [PermissionsEnum.MOVEMENTS_DAY_VIEW],
    },
    loadComponent: () => import('../inventory/pages/movements/movements-list/movements-list.component'),
  },

  {
    path: 'inventory-closures',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.inventory.inventoryClosure',
      permissionsNeeded: [PermissionsEnum.DAILY_INVENTORY_VIEW, PermissionsEnum.GENERAL_INVENTORY_VIEW],
    },
    loadComponent: () => import('../inventory/pages/inventory-closures/inventory-closures.component'),
  },
  {
    path: 'purchases',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.inventory.purchases',
      permissionsNeeded: [PermissionsEnum.MANUAL_PURCHASE_VIEW],
    },
    children: [
      {
        path: '',
        loadComponent: () => import('../inventory/pages/purchases-list/purchases-list.component'),
      },
      {
        path: 'create-purchase',
        data: {
          breadcrumb: 'routes.inventory.newPurchase',
        },
        loadComponent: () => import('./components/create-purchase/create-purchase.component'),
      },
    ],
  },
  {
    path: 'transfers',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.inventory.transfers',
      permissionsNeeded: [PermissionsEnum.RECEIVED_VIEW, PermissionsEnum.SENT_VIEW],
    },
    loadComponent: () => import('../inventory/pages/transfer/transfer.component'),
  },
  {
    path: 'waste',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.inventory.waste',
      permissionsNeeded: [PermissionsEnum.RAW_VIEW, PermissionsEnum.COMPLETE_VIEW],
    },
    loadComponent: () => import('../inventory/pages/waste/waste.component'),
  },


  {
    path: 'closure',
    data: {
      breadcrumb: 'routes.inventory.closure',
    },
    loadComponent: emptySection,
  },
]
